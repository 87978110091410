<template>
  <BaseDropDown btn-class="btn sm grey" ref="ddownSearch" @opened="setFocus">
    <template #btn>
      <BaseIcon class="ic-18 grey" :icon="icon" />
      {{ name }}
    </template>
    <template #body>
      <div class="search-links__body grid ggap-5 p-5">
        <div class="search-links__search pos-r z0">
          <input
            ref="searchInput"
            type="text"
            class="search-links__search-input form-item__input sm pl-10 pr-10 w-100"
            placeholder="Поиск"
            v-model="searchText"
          />
          <div class="search-links__search-right pos-a flex ai-c jc-c">
            <BaseLoad v-if="propLoad" class="rel sm" />
            <button
              v-else-if="!propLoad && searchText"
              class="btn outline sm rounded cube-24"
              @click.prevent="clearAction"
            >
              <BaseIcon class="ic-18 grey" icon="close" />
            </button>
          </div>
        </div>
        <div class="search-links__list grid">
          <template v-if="filteredList && filteredList.length">
            <button
              v-for="item of filteredList"
              :key="item.id"
              class="btn sm transparent-grey cut block ta-left"
              @click.prevent="setLink(item)"
            >
              <slot name="item" :item="item">
                {{ item.name }}
              </slot>
            </button>
          </template>
          <div v-else class="ta-center p-10">Ничего не найдено</div>
        </div>
      </div>
    </template>
  </BaseDropDown>
</template>

<script setup>
import { debounce } from 'lodash'
import { defineEmits, defineProps, nextTick, ref, toRefs, computed, watch } from 'vue'
import { BaseLoad, BaseIcon, BaseDropDown } from '@/components'

// Emits
const emits = defineEmits(['setLink', 'search'])

// Props
const props = defineProps({
  name: {
    type: String,
    default: 'Name'
  },
  icon: {
    type: String,
    default: 'user'
  },
  list: {
    type: Array,
    default: () => []
  },
  propLoad: {
    type: Boolean,
    default: false
  }
})

// Data
const { name, icon, list, propLoad } = toRefs(props)
const ddownSearch = ref()
const searchInput = ref()
const searchText = ref('')

// Computed
const filteredList = computed(() => {
  ddownSearch.value.reCalc()
  return list.value
})

// Watch
watch(searchText, debounce(searchAction, 1000))

// Methods
function setLink(item) {
  emits('setLink', item)
  ddownSearch.value.closeAction()
}
function setFocus() {
  nextTick(() => searchInput.value && searchInput.value.focus())
}
function clearAction() {
  searchText.value = ''
  nextTick(() => searchInput.value && searchInput.value.focus())
}
function searchAction() {
  emits('search', searchText.value)
}
</script>

<style lang="scss" scoped>
.search-links {
  &__list {
    max-width: 350px;
    max-height: 250px;
    overflow: auto;
  }

  &__search {
    &-right {
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
    }
  }
}
</style>
